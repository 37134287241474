













































import CookieComponent from './CookieComponent'
import { Component, Prop, Watch } from 'vue-property-decorator';

import CookieDialog from './CookieDialog.vue';

@Component({
  components: {
    CookieDialog
  }
})
export default class CookieBanner extends CookieComponent {  
  
  // Members
  public opened = false;
  public settingOpened = false;

  // Properties
  @Prop({required: false, default: false})
  public showIcon!: boolean;

  // Component Lifecycle Methods:
  public mounted(): void {
    this.opened = this.consentRequired;
  }

  // Methods
  public acceptAll(): void {
    for(const category of this.cookieCategories) {
      category.isActive = true;
    }
    this.saveSettings();
    this.executeSettings();
    this.consentRequired = false;
    this.close();
  }

  public close() {
    this.opened = false;
  }

  public openSettings(): void {
    this.close();
    this.settingOpened = true;
  }
}
