/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterDataItem } from '../models/MasterDataItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BrandStatesService {

    /**
     * Gets all known brand states and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getBrandStates(): CancelablePromise<Array<MasterDataItem>> {
        return __request({
            method: 'GET',
            path: `/api/v1/BrandStates`,
        });
    }

    /**
     * Adds or updates a brand state in the data storage.
     * @param requestBody The new or existing brand state.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static upsertBrandState(
requestBody?: MasterDataItem,
): CancelablePromise<MasterDataItem> {
        return __request({
            method: 'PUT',
            path: `/api/v1/BrandStates`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets the brand state by its key and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The key.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getBrandStateByKey(
key: string,
): CancelablePromise<MasterDataItem> {
        return __request({
            method: 'GET',
            path: `/api/v1/BrandStates/${key}`,
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Deletes the brand state by its key.
     * @param key The key.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteBrandState(
key: string,
): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/v1/BrandStates/${key}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}