

















import { BusyObject } from "@/models/Busy";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import BrandThumbnailCard, { BrandThumbnail } from './BrandThumbnailCard.vue'

interface BrandStatistic {
    name: string;
    executedUtc: Date;
    brandThumbnails: BrandThumbnail[];
    measures: {
        [category: string]: {
            [measure: string]: number;
        };
    };
}

@Component({
    components: {
        VueSlickCarousel,
        BrandThumbnailCard
    }
})
export default class BrandThumbnailsCarousel extends Vue {
    // Member:
    public statistic = new BusyObject<BrandStatistic>();

    // Properties:
    @Prop({required: false})
    public url?: string;

    @Prop({required: false})
    public data?: any;

    // Watcher
    @Watch('url')
    public onUrlChanged() {
        this.loadStatistic();
    }

    @Watch('data')
    public onDataChanged() {
        this.loadStatistic();
    }

    // Getter:
    public get itemsToShow(): number {
        return Math.ceil(window.innerWidth / 500);
    }

    // Component Lifecycle Methods:
    public mounted() {
        this.loadStatistic();
    }

    // Methods:
    public async loadStatistic() {
        await this.statistic.load(async () => {
            if(this.data) 
                return this.data as BrandStatistic;
            else if(this.url) 
                return await this.$util.getData(this.url);
            else 
                return null;
        });
    }
}
