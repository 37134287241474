import _Vue from "vue";

interface Statistic {
    measures: {
        [category: string]: {
            [key: string]: number;
        };
    };
}

export class Statistics {
    public getBrandTotalOrZero(statistic: any): number {
        try {
            return (statistic as Statistic)?.measures['brand']['total'] ?? 0;
        }
        catch(error) {
            console.log(error);
            return 0;
        }
    }
}

export const statistics = new Statistics();

export function StatisticsPlugin(Vue: typeof _Vue): void {
    Vue.prototype.$statistics = statistics;
}

declare module 'vue/types/vue' {
    interface Vue {
        $statistics: Statistics;
    }
}