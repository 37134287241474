import _Vue from "vue";
import { Brand } from '@/api/braendz/models/Brand'
import { niceClassIcons } from "@/models/NiceClasses";
import { BrandAddress, BrandIndexItem, Product } from "@/api/braendz";
import { brandTypeIcons } from "@/models/BrandTypes";
import { brandStateCategoryIcons } from '@/models/BrandStateCategories';

import objectHash from "object-hash";

export class Braendz {

    public registrationOfficeCodeFlag(key: string | undefined | null): string | null {
        switch(key?.toLowerCase())
        {
            case 'de':
                return require('@/assets/flags/de.png');
            case 'eu':
                return require('@/assets/flags/eu.png');
            case 'em':
                return require('@/assets/flags/eu.png');
            case 'fr':
                return require('@/assets/flags/fr.png');
            case 'at':
                return require('@/assets/flags/at.png');
            case 'es':
                return require('@/assets/flags/es.png');
            case 'ch':
                return require('@/assets/flags/ch.png');
            case 'us':
                return require('@/assets/flags/us.png');
            default:
                return null;
        }
    }

    public getBrandLogoUrl(brand: BrandIndexItem | Brand | null | undefined): string | null {
        const url = brand?.brandLogoUrl;
        if (url) {
            return this.getBrandLogoUrlByRelativeUrl(url);
        }
        return null;
    }

    public getBrandLogoUrlByRelativeUrl(url: string): string {
        if(/^(?:[a-z+]+:)?\/\//i.test(url)) {
            // Case e.g. for ES: URL is absolute and can be requested directly:
            return url;
        } else {
            // Normal case: URL is relative and needs to be requested via API: 
            return `${process.env.VUE_APP_BRAENDZ_API_BASE_URL}/api/v1/BrandImages/${Buffer.from(url).toString('base64')}?base64Encoded=true`;
        }
    }

    public getNiceClassIcon(key?: string): string | undefined {
        return niceClassIcons.find(i => i.key === key)?.icon;
    }

    public getBrandTypeIcon(key?: string): string | undefined {
        return brandTypeIcons.find(i => i.key === key)?.icon;
    }

    public getBrandStateCategoryIcon(key?: string): string | undefined {
        return brandStateCategoryIcons.find(i => i.key === key)?.icon;
    }

    public getAddressLine(address: BrandAddress | null | undefined): string {
        const lines = this.getAddressLines(address);
        return lines.join(', ');
    }

    public getAddressLines(address: BrandAddress | null | undefined): string[] {
        const result: string[] = [];

        if(address?.addressLine) {
            address.addressLine.split(/[,;]\s*/).forEach(i => result.push(i)); 
        }
        else {
            if(address?.companyName){
                result.push(address.companyName);
            }
            else if(address?.name) {
                result.push(address.name);
            }

            
            if(address?.firstName || address?.lastName) {
                let name = address.firstName ?? "";
                if(address.lastName) {
                    if(name) {
                        name += " ";
                    }
                    name += address.lastName;
                }
                result.push(name);
            }

            if(address?.street) {
                result.push(address.street);
            }

            if(address?.postOfficeBox || address?.postalCode || address?.city) {
                let location = address.postalCode ?? address.postOfficeBox ?? "";

                if(address.city) {
                    if(location) {
                        location += " ";
                    }
                    location += address.city;
                }
                result.push(location);
            }

            if(address?.state) {
                result.push(address.state);
            }

            if(address?.country || address?.countryCode) {
                result.push(address.country ?? address.countryCode ?? "");
            }
        }

        return result.filter((value, index, array) => array.indexOf(value) === index);
    }

    public checkSubscriptionTransformation(productFrom: Product | null | undefined, productTo: Product | null | undefined) : 'Subscribe' | 'Downgrade' | 'Same' | 'Upgrade' {
        if(!productFrom?.upgradeLevel) return 'Subscribe'
        else if(!productTo?.upgradeLevel) return 'Downgrade';
        else if(productTo.upgradeLevel > productFrom.upgradeLevel) return 'Upgrade'
        else if(productTo.upgradeLevel < productFrom.upgradeLevel) return 'Downgrade';
        else return 'Same';
    }

    
    // public async isDummyLogo(url: string): Promise<boolean> {
    //     const imageData = await fetch(url);
    //     if(/^(?:[a-z+]+:)?\/\//i.test(url)) {
    //         console.log(objectHash(imageData.body));
    //     }
    //     return false;
    // }
}

export const braendz = new Braendz();

export function BraendzPlugin(Vue: typeof _Vue): void {
    Vue.prototype.$braendz = braendz;
}

declare module 'vue/types/vue' {
    interface Vue {
        $braendz: Braendz;
    }
}