




































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class SocialMediaButton extends Vue {
    // Properties:

    // Component Properties:
    @Prop({ required: true })
    public type!: string;

    // baidu, buffer, email, evernote, facebook, flipboard, hackernews, instapaper, line, linkedin, messenger, odnoklassniki, pinterest, pocket, quora, reddit, skype, sms, stumbleupon, telegram, tumblr, twitter, viber, vk, weibo, whatsapp, wordpress, xing, yammer
    @Prop({ required: true })
    public network!: string;

    @Prop({ required: true })
    public url!: string;

    @Prop({ required: true })
    public title!: string;

    @Prop({ required: false })
    public description?: string;

    @Prop({ required: false })
    public quote?: string;

    @Prop({ required: false })
    public hashtags?: string;

    @Prop({ required: false, default: false })
    public light!: boolean;

    @Prop({ required: false, default: false })
    public dark!: boolean;

    // Getter
    public get icon(): string | null {
        switch(this.network) {
            case 'email': return 'fa-light fa-at';
            case 'evernote': return 'fa-brands fa-evernote';
            case 'facebook': return 'fa-brands fa-facebook';
            case 'linkedin': return 'fa-brands fa-linkedin';
            case 'messenger': return 'fa-brands fa-facebook-messenger';
            case 'pinterest': return 'fa-brands fa-pinterest';
            case 'quora': return 'fa-brands fa-quora';
            case 'reddit': return 'fa-brands fa-reddit';
            case 'skype': return 'fa-brands fa-skype';
            case 'sms': return 'fa-light fa-comment-sms';
            case 'twitter': return 'fa-brands fa-x-twitter';
            case 'whatsapp': return 'fa-brands fa-whatsapp';
            case 'weibo': return 'fa-brands fa-weibo';
            case 'wordpress': return 'fa-brands fa-wordpress';
            default: return null;
        }
    }

    // baidu, buffer, flipboard, hackernews, instapaper, line, odnoklassniki, pocket, stumbleupon, telegram, tumblr, viber, vk, xing, yammer

    public get logo(): string | null {
        switch(this.network) {
            case 'baidu': return require('@/assets/social/baidu.png');
            case 'buffer': return require('@/assets/social/buffer.png');
            case 'flipboard': return require('@/assets/social/flipboard.png');
            case 'hackernews': return require('@/assets/social/hackernews.png');
            case 'instapaper': return require('@/assets/social/instapaper.png');
            case 'line': return require('@/assets/social/line.png');
            case 'odnoklassniki': return require('@/assets/social/odnoklassniki.png');
            case 'pocket': return require('@/assets/social/pocket.png');
            case 'stumbleupon': return require('@/assets/social/stumbleupon.png');
            case 'telegram': return require('@/assets/social/telegram.png');
            case 'tumblr': return require('@/assets/social/tumblr.png');
            case 'viber': return require('@/assets/social/viber.png');
            case 'vk': return require('@/assets/social/vk.png');
            case 'xing': return require('@/assets/social/xing.png');
            case 'yammer': return require('@/assets/social/yammer.png');
            default: return null;
        }
    }

    public get color(): string | null {
        switch(this.network) {
            case 'evernote': return '#33A821';
            case 'facebook': return '#3578F7';
            case 'linkedin': return '#2664BF';
            case 'messenger': return '#538CFF';
            case 'pinterest': return '#DC001A';
            case 'quora': return '#C61E09';
            case 'reddit': return '#F54200';
            case 'skype': return '#3DAFF6';
            case 'twitter': return '#3D9DF3';
            case 'whatsapp': return '#4AD05B';
            case 'weibo': return '#D61B22';
            case 'wordpress': return '#30769E';
            default: return null;
        }
    }

    public get label(): string | null {
        switch(this.network) {
            case 'baidu': return 'Baidu';
            case 'buffer': return 'Buffer';
            case 'email': return 'Email';
            case 'evernote': return 'Evernote';
            case 'facebook': return 'Facebook';
            case 'flipboard': return 'Flipboard';
            case 'hackernews': return 'HackerNews';
            case 'instapaper': return 'Instapaper';
            case 'line': return 'Line';
            case 'linkedin': return 'LinkedIn';
            case 'messenger': return 'Messenger';
            case 'odnoklassniki': return 'Odnoklassniki';
            case 'pinterest': return 'Pinterest';
            case 'pocket': return 'Pocket';
            case 'quora': return 'Quora';
            case 'reddit': return 'Reddit';
            case 'skype': return 'Skype';
            case 'sms': return 'SMS';
            case 'stumbleupon': return 'StumbleUpon';
            case 'telegram': return 'Telegram';
            case 'tumblr': return 'Tumblr';
            case 'twitter': return 'X';
            case 'viber': return 'Viber';
            case 'vk': return 'Vk';
            case 'weibo': return 'Weibo';
            case 'whatsapp': return 'Whatsapp';
            case 'wordpress': return 'Wordpress';
            case 'xing': return 'Xing';
            case 'yammer': return 'Yammer';
            default: return null;
        }
    }

    // Component Lifecycle:

    // Methods:
}
