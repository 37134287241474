
































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Process } from '@/api/braendz';
import BrandDetailsTextField from './BrandDetailsTextField.vue';

@Component({
    components: {
        BrandDetailsTextField
    }
})
export default class BrandDetailsProcessTimeline extends Vue {

    // Properties
    @Prop({ required: true })
    public processes: Process[] | null | undefined;

    @Prop({ required: false })
    public width?: number;

}
