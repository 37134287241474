







import { BusyObject } from "@/models/Busy";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Chart, { KeyValueData, Series } from '@/components/Statistics/Chart.vue';

interface Statistic {
    measures: {
        [category: string]: {
            [key: string]: number;
        };
    };
}

@Component({
    components: {
        Chart
    }
})
export default class BrandsByOfficeBarChart extends Vue {
    // Member:
    public statistic = new BusyObject<Statistic>();

    // Properties:
    @Prop({required: false})
    public url?: string;

    @Prop({required: false})
    public data?: any;

    @Prop({required: false})
    public height?: number | string;

    @Prop({required: false})
    public width?: number | string;

    // Watcher
    @Watch('url')
    public onUrlChanged() {
        this.loadStatistic();
    }

    @Watch('data')
    public onDataChanged() {
        this.loadStatistic();
    }

    // Getter:
    public get series(): Series[] {
        const result: Series[] = [];

        try {
            const office = this.statistic.object?.measures['registrationOffice'];

            if(!office) {
                return result;
            }

            const series = new Series('Trademarks by offices');

            if(office['us']) {
                series.add({key: this.$t('statistics.globalBrandsByOfficeChart.us').toString(), value: office['us']} as KeyValueData)
            }
            if(office['de']) {
                series.add({key: this.$t('statistics.globalBrandsByOfficeChart.de').toString(), value: office['de']} as KeyValueData)
            }
            if(office['at']) {
                series.add({key: this.$t('statistics.globalBrandsByOfficeChart.at').toString(), value: office['at']} as KeyValueData)
            }
            if(office['es']) {
                series.add({key: this.$t('statistics.globalBrandsByOfficeChart.es').toString(), value: office['es']} as KeyValueData)
            }
            if(office['ch']) {
                series.add({key: this.$t('statistics.globalBrandsByOfficeChart.ch').toString(), value: office['ch']} as KeyValueData)
            }
            if(office['fr']) {
                series.add({key: this.$t('statistics.globalBrandsByOfficeChart.fr').toString(), value: office['fr']} as KeyValueData)
            }
            if(office['em']) {
                series.add({key: this.$t('statistics.globalBrandsByOfficeChart.em').toString(), value: office['em']} as KeyValueData)
            }

            result.push(series);
        }
        catch(error) {
            console.log(error);
        }
        
        return result;
    }

    // Component Lifecycle Methods:
    public mounted() {
        this.loadStatistic();
    }

    // Methods:
    public async loadStatistic() {
        await this.statistic.load(async () => {
            if(this.data) 
                return this.data as Statistic;
            else if(this.url) 
                return await this.$util.getData(this.url);
            else 
                return null;
        });
    }
}
