export enum SubscriptionState {
    Active = "Active",
    NotFound = "NotFound",
    Error = "Error",
    UnknownStatus = "UnknownStatus",
    Suspended = "Suspended",
    Cancelled = "Cancelled",
    Expired = "Expired",
    ApprovalPending = "ApprovalPending",
    Approved = "Approved",
    NotChecked = "NotChecked",
    Consumed = "Consumed",
    Inactive = "Inactive"
}