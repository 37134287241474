import Vue from 'vue'
import { Component } from 'vue-property-decorator';

@Component
export default class CookieComponent extends Vue {
    public expires = 60 * 60 * 24 * 365;

    public cookieCategories = [{
        key: 'necessary',
        text: 'Strictly necessary cookies',
        description: "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.",
        arbitrable: false,
        isActive: true,
        cookies: [{
            name: 'cc_show',
            domain: 'braendz.com',
            description: 'Used by Braendz to persist the cookie consent display state.'
        },
        {
            name: 'cc_category_necessary_active',
            domain: 'braendz.com',
            description: "Used by Braendz to persist the cookie setting for 'Strictly necessary cookies'."
        },
        {
            name: 'cc_category_analytics_active',
            domain: 'braendz.com',
            description: "Used by Braendz to persist the cookie setting for 'Performance and analytics cookies'."
        }]
      },
      {
        key: 'analytics',
        text: 'Performance and analytics cookies',
        description: "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.",
        arbitrable: true,
        isActive: this.analyticsActive,
        deactivate: () => this.stopAnalyticServices(),
        activate: () => this.startAnalyticServices(),
        cookies: [{
            name: '_ga',
            domain: 'braendz.com',
            description: 'Used by Google Analytics to distinguish users.'
        },
        {
            name: '_ga_<id>',
            domain: 'braendz.com',
            description: 'Used by Google Analytics to persist session state.'
        }]
      },
    ];

    // Getter:
    public get consentRequired(): boolean {
        const value = this.$cookies.get('cc_show');

        return typeof value === 'boolean' ? value 
            : typeof value === 'string' ? (value === 'true') 
            : true;
    }
    public set consentRequired(value: boolean) {
        this.$cookies.set('cc_show', value, { expires: this.expires });
    }

    public get analyticsActive(): boolean {
        return this.isActive('analytics');
    }
    public set analyticsActive(value: boolean) {
        this.setActive('analytics', value);
    }

    // Methods:
    public isActive(category: string): boolean {
        const value = this.$cookies.get(`cc_category_${category}_active`);

        return typeof value === 'boolean' ? value 
            : typeof value === 'string' ? (value === 'true') 
            : false;
    }
    public setActive(category: string, value: boolean) {
        this.$cookies.set(`cc_category_${category}_active`, value, { expires: this.expires });
    }

    public saveSettings() {
        for(const category of this.cookieCategories) {
            this.setActive(category.key, category.isActive);
        }
    }

    public executeSettings() {
        for(const category of this.cookieCategories) {
            if(category.isActive && category.activate) {
                category.activate(); 
            }
            else if(!category.isActive && category.deactivate) {
                category.deactivate();
            }
        }
    }

    public startAnalyticServices() {
        this.$gtag.optIn();
        this.$gtag.pageview({page_path: this.$router.currentRoute.path});
    }

    public stopAnalyticServices() {
        this.$gtag.optOut();
    }
}
