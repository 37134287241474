/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompletionPrompt } from '../models/CompletionPrompt';
import type { CompletionResult } from '../models/CompletionResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AiService {

    /**
     * Executes an AI completion prompt.
     * @param requestBody The user prompt.
     * @returns CompletionResult Success
     * @throws ApiError
     */
    public static executeCompletion(
requestBody?: CompletionPrompt,
): CancelablePromise<CompletionResult> {
        return __request({
            method: 'POST',
            path: `/api/v1/Ai/completions`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

}