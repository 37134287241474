export const niceClassIcons = [
    { key: '1', icon: 'fa-light fa-flask-vial' },  
    { key: '2', icon: 'fa-light fa-fill-drip' },  
    { key: '3', icon: 'fa-light fa-spray-can-sparkles' },  
    { key: '4', icon: 'fa-light fa-oil-can-drip' },  
    { key: '5', icon: 'fa-light fa-pills' },  
    { key: '6', icon: 'fa-light fa-layer-group' },  
    { key: '7', icon: 'fa-light fa-engine' },  
    { key: '8', icon: 'fa-light fa-screwdriver-wrench' },  
    { key: '9', icon: 'fa-light fa-camcorder' },  
    { key: '10', icon: 'fa-light fa-scalpel' },  
    { key: '11', icon: 'fa-light fa-dryer-heat' },  
    { key: '12', icon: 'fa-light fa-car-side' },  
    { key: '13', icon: 'fa-light fa-gun' },  
    { key: '14', icon: 'fa-light fa-watch' },  
    { key: '15', icon: 'fa-light fa-guitars' },  
    { key: '16', icon: 'fa-light fa-box-open-full' },  
    { key: '17', icon: 'fa-light fa-boxes-packing' },  
    { key: '18', icon: 'fa-light fa-suitcase-rolling' },  
    { key: '19', icon: 'fa-light fa-block-brick' },  
    { key: '20', icon: 'fa-light fa-cabinet-filing' },  
    { key: '21', icon: 'fa-light fa-kitchen-set' },  
    { key: '22', icon: 'fa-light fa-campground' },  
    { key: '23', icon: 'fa-light fa-reel' },  
    { key: '24', icon: 'fa-light fa-blanket' },  
    { key: '25', icon: 'fa-light fa-uniform-martial-arts' },  
    { key: '26', icon: 'fa-light fa-scarf' },  
    { key: '27', icon: 'fa-light fa-rug' },  
    { key: '28', icon: 'fa-light fa-cards' },  
    { key: '29', icon: 'fa-light fa-egg-fried' },  
    { key: '30', icon: 'fa-light fa-plate-wheat' },  
    { key: '31', icon: 'fa-light fa-wheat-awn' },  
    { key: '32', icon: 'fa-light fa-beer-mug' },  
    { key: '33', icon: 'fa-light fa-wine-glass' },  
    { key: '34', icon: 'fa-light fa-smoking' },  
    { key: '35', icon: 'fa-light fa-chart-line-up' },  
    { key: '36', icon: 'fa-light fa-building-columns' },  
    { key: '37', icon: 'fa-light fa-user-helmet-safety' },  
    { key: '38', icon: 'fa-light fa-mobile-signal-out' },  
    { key: '39', icon: 'fa-light fa-bus' },  
    { key: '40', icon: 'fa-light fa-recycle' },  
    { key: '41', icon: 'fa-light fa-user-graduate' },  
    { key: '42', icon: 'fa-light fa-atom' },  
    { key: '43', icon: 'fa-light fa-fork-knife' },  
    { key: '44', icon: 'fa-light fa-house-medical' },  
    { key: '45', icon: 'fa-light fa-scale-balanced' }
];