














import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

import { SubscriptionState as SubscriptionStateEnum } from '@/models/Subscription'


@Component({
    components: {
    }
})
export default class SubscriptionState extends Vue {

    // Properties
    public statesConfig = [
        { state: SubscriptionStateEnum.Active, color: "green darken-3", textLocalizationPath: "subscription.state.active" },
        { state: SubscriptionStateEnum.NotChecked, color: "grey darken-3", textLocalizationPath: "subscription.state.notChecked" },
        { state: SubscriptionStateEnum.UnknownStatus, color: "grey darken-3", textLocalizationPath: "subscription.state.unknownStatus" },
        { state: SubscriptionStateEnum.NotFound, color: "red darken-3", textLocalizationPath: "subscription.state.notFound" },
        { state: SubscriptionStateEnum.Error, color: "red darken-3", textLocalizationPath: "subscription.state.error" },
        { state: SubscriptionStateEnum.Suspended, color: "red darken-3", textLocalizationPath: "subscription.state.suspended" },
        { state: SubscriptionStateEnum.Cancelled, color: "red darken-3", textLocalizationPath: "subscription.state.cancelled" },
        { state: SubscriptionStateEnum.Expired, color: "red darken-3", textLocalizationPath: "subscription.state.expired" },
        { state: SubscriptionStateEnum.ApprovalPending, color: "red darken-3", textLocalizationPath: "subscription.state.approvalPending" },
        { state: SubscriptionStateEnum.Approved, color: "red darken-3", textLocalizationPath: "subscription.state.approved" },
        { state: SubscriptionStateEnum.Consumed, color: "red darken-3", textLocalizationPath: "subscription.state.consumed" },
        { state: SubscriptionStateEnum.Inactive, color: "red darken-3", textLocalizationPath: "subscription.state.inactive" }
    ];

    @Prop({ required: true })
    public state!: string;

    @Prop({ required: false, default:"Chip" })
    public design!: "Chip" | "Text";

    // Getter & Setter
    public get color(): string | null {
        return this.statesConfig.find(i => i.state === this.state)?.color 
            ?? this.statesConfig.find(i => i.state === SubscriptionStateEnum.UnknownStatus)?.color 
            ?? null;
    }

    public get i18nLabel(): string | null {
        return this.statesConfig.find(i => i.state === this.state)?.textLocalizationPath ?? null;
    }

    // Watcher

    // Lifecycle Methods:

    // Methods:
}
