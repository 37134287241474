/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PayPalConfigurations } from '../models/PayPalConfigurations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ConfigurationsService {

    /**
     * Gets the client configurations for PayPal.
     * @returns PayPalConfigurations Success
     * @throws ApiError
     */
    public static getPayPalConfigurations(): CancelablePromise<PayPalConfigurations> {
        return __request({
            method: 'GET',
            path: `/api/v1/Configurations/PayPal`,
        });
    }

}