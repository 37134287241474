import Vue from 'vue'
import Vuex from 'vuex'
import { AccountInfo } from '@azure/msal-browser';
import { User, Permission, Account, MasterDataItem, UsersService, PermissionsService, AccountsService, BrandTypesService, BrandStatesService, RegistrationOfficeCodesService, NiceClassesService, BrandBookmark, BrandExclusion, BrandBookmarksService, BrandExclusionsService, BrandBookmarkContainer, BrandBookmarkContainersService, BrandStateCategoryService, Subscription, SubscriptionsService } from '@/api/braendz'
import { BusyObject, BusyList } from '@/models/Busy';
import { PermissionResourceType } from '@/models/PermissionResourceType';
import { PermissionLevel } from '@/models/PermissionLevel';
import { keys } from 'object-hash';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userAccount: null as AccountInfo | null,
    user: new BusyObject<User>(),
    permissions: new BusyList<Permission>(),
    account: new BusyObject<Account>(),
    activeSubscription: new BusyObject<Subscription>(),
    
    // Masterdata:
    registrationOfficeCodes: new BusyList<MasterDataItem>(),
    brandTypes: new BusyList<MasterDataItem>(),
    brandStates: new BusyList<MasterDataItem>(),
    brandStateCategories: new BusyList<MasterDataItem>(),
    niceClasses: new BusyList<MasterDataItem>(),

    // Brand Bookmarks & Exclusion:
    brandBookmarkContainers: new BusyList<BusyObject<BrandBookmarkContainer>>(),
    brandExclusions: new BusyList<BusyObject<BrandExclusion>>(),

    // Cookie Settings:
    
  },
  mutations: {
    setUserAccount: (state, userAccount: AccountInfo | null) => {
      state.userAccount = userAccount;
    },
    setActiveSubscription: (state, subscription: Subscription | null) => {
      state.activeSubscription.object = subscription;
    }
  },
  getters: {
    isUserAuthenticated: state => (): boolean => {
      return !!state.userAccount;
    },
    hasPermission: state => (resource: PermissionResourceType, level: PermissionLevel): boolean => {
      if(!state.permissions.list) {
        return false;
      }

      // Check if the user has the required level globally:
      const golbalPermission = state.permissions.list.find(p => p.resource === PermissionResourceType.Global);
      if(golbalPermission && golbalPermission.level && (level === (golbalPermission.level & level))) {
        return true;
      }

      // Check if the user has the required level for the requested resource:
      const resourcePermission = state.permissions.list.find(p => p.resource === resource);
      if(resourcePermission && resourcePermission.level && (level === (resourcePermission.level & level))) {
        return true;
      }

      return false;
    },
    hasFeature: state => (key: string): boolean => {
      if(!state.activeSubscription.object) {
        return false;
      }

      const features = state.activeSubscription.object.product?.features ?? [];
      for(const subscriptionPackage of state.activeSubscription.object.packages ?? []) {
        if(subscriptionPackage.features) {
          features.concat(subscriptionPackage.features);
        }
      }
      return features.find(f => f === key) !== undefined;
    },

    // Master Data
    getRegistrationOfficeCode: state => (key: string): MasterDataItem | undefined => {
      return state.registrationOfficeCodes.list?.find(i => i.key?.toLocaleLowerCase() === key?.toLocaleLowerCase());
    },
    getBrandType: state => (key: string): MasterDataItem | undefined => {
      return state.brandTypes.list?.find(i => i.key?.toLocaleLowerCase() === key?.toLocaleLowerCase());
    },
    getBrandState: state => (key: string): MasterDataItem | undefined => {
      return state.brandStates.list?.find(i => i.key?.toLocaleLowerCase() === key?.toLocaleLowerCase());
    },
    getBrandStateCategory: state => (key: string): MasterDataItem | undefined => {
      return state.brandStateCategories.list?.find(i => i.key?.toLocaleLowerCase() === key?.toLocaleLowerCase());
    },
    getNiceClass: state => (number: string | number): MasterDataItem | undefined => {
      if(typeof number === 'string') {
        return state.niceClasses.list?.find(i => i.key === number);
      }
      else {
        return state.niceClasses.list?.find(i => i.key === number.toString());
      }
    },

    // Brand Exclusion:
    isBrandExcluded: state => (id: string): boolean => {
      return state.brandExclusions.list?.findIndex(i => i.object?.id === id) > -1;
    }
  },
  actions: {
    async refreshUser( { commit, state }): Promise<void> {
      await state.user.load(async () => {
        return await UsersService.getCurrentUser();
      });
    },
    async refreshPermissions( { commit, state }): Promise<void> {
      await state.permissions.load(async () => {
        return await PermissionsService.getCurrentUserPermissions();
      });
    },
    async refreshDefaultAccount( { commit, state }): Promise<void> {
      if(state.userAccount == null) {
        state.account.object = null;
      } else {
        await state.account.load(async () => {
          return await AccountsService.getCurrentUserDefaultAccount();
        });
      }
    },
    async refreshActiveSubscription( { commit, state }): Promise<void> {
      await state.activeSubscription.load(async () => {
        return await SubscriptionsService.getActiveSubscriptionCurrentUserDefaultAccount();
      });
    },
    async updateUserContext({ commit, dispatch }, userAccount): Promise<void> {
      commit('setUserAccount', userAccount);
      dispatch('refreshUser');
      dispatch('refreshActiveSubscription');
      dispatch('refreshPermissions');
      dispatch('refreshDefaultAccount');

      dispatch('refreshBrandBookmarkContainers');
      dispatch('refreshBrandExclusions');
    },
    
    // Master data:
    async refreshRegistrationOfficeCodes( { commit, state }): Promise<void> {
      await state.registrationOfficeCodes.load(async () => {
        return await RegistrationOfficeCodesService.getRegistrationOfficeCodes();
      });
    },
    async refreshBrandTypes( { commit, state }): Promise<void> {
      await state.brandTypes.load(async () => {
        return await BrandTypesService.getBrandTypes();
      });
    },
    async refreshBrandStates( { commit, state }): Promise<void> {
      await state.brandStates.load(async () => {
        return await BrandStatesService.getBrandStates();
      });
    },
    async refreshBrandStateCategories( { commit, state }): Promise<void> {
      await state.brandStateCategories.load(async () => {
        return await BrandStateCategoryService.getBrandStateCategories();
      });
    },
    async refreshNiceClasses( { commit, state }): Promise<void> {
      await state.niceClasses.load(async () => {
        return await NiceClassesService.getNiceClasses();
      });
    },
    async updateMasterData({ dispatch }): Promise<void> {
      dispatch('refreshRegistrationOfficeCodes');
      dispatch('refreshBrandTypes');
      dispatch('refreshBrandStates');
      dispatch('refreshBrandStateCategories');
      dispatch('refreshNiceClasses');
    },

    // Brand Bookmarks & Exclusion:
    async refreshBrandBookmarkContainers({ commit, state, getters }): Promise<void> {
      if(getters.isUserAuthenticated()) {
        await state.brandBookmarkContainers.load(async () => {
          return (await BrandBookmarkContainersService.getBrandBookmarkContainersCurrentUserDefaultAccount()).map(i => new BusyObject(i));
        }); 
      }
      else {
        state.brandBookmarkContainers.list = [];
      }
    },
    async addBrandBookmarkContainer({ state, getters }, brandBookmarkContainer: BrandBookmarkContainer): Promise<void> {
      if(getters.isUserAuthenticated()) {
        const busyBrandBookmark = new BusyObject<BrandBookmarkContainer>(brandBookmarkContainer);

        await busyBrandBookmark.create(async () => {
          return BrandBookmarkContainersService.upsertBrandBookmarkContainerCurrentUserDefaultAccount(brandBookmarkContainer);
        });

        state.brandBookmarkContainers.list.push(busyBrandBookmark);
      }
    },
    async removeBrandBookmarkContainer({ state, getters }, id: number): Promise<void> {
      if(getters.isUserAuthenticated()) {
        const container = state.brandBookmarkContainers.list.find(i => i.object?.id === id);

        if(container) {
          await container.delete(async () => {
            BrandBookmarkContainersService.deleteBrandBookmarkContainer(id);
            return null;
          });
          const index = state.brandBookmarkContainers.list.indexOf(container);
          if(index > -1) state.brandBookmarkContainers.list.splice(index);
        }
        else {
          await new BusyObject<BrandBookmarkContainer>().delete(async () => {
            BrandBookmarkContainersService.deleteBrandBookmarkContainer(id);
            return null;
          });
        }
      }
    },

    async addBrandBookmark({ state, getters }, payload: { containerId: number, brandId: string }): Promise<void> {
      if(getters.isUserAuthenticated()) {
        const newBookmark = new BusyObject<BrandBookmark>();

        await newBookmark.create(async () => {
          return BrandBookmarksService.upsertBrandBookmark(payload.containerId, { id: payload.brandId });
        });

        const container = state.brandBookmarkContainers.list.find(i => i.object?.id === payload.containerId);

        if(container && newBookmark.object) {
          const index = container.object?.brandBookmarks?.findIndex(i => i.id === payload.brandId);
          if(index && index > -1) container.object?.brandBookmarks?.splice(index);
          container.object?.brandBookmarks?.push(newBookmark.object);
        }
      }
    },
    async removeBrandBookmark({ state, getters }, payload: { containerId: number, brandId: string }): Promise<void> {
      if(getters.isUserAuthenticated()) {
        await new BusyObject<BrandBookmark>().delete(async () => {
          return BrandBookmarksService.deleteBrandBookmark(payload.containerId, payload.brandId);
        });

        const container = state.brandBookmarkContainers.list.find(i => i.object?.id === payload.containerId);

        if(container) {
          const index = container.object?.brandBookmarks?.findIndex(i => i.id === payload.brandId);
          if(index && index > -1) container.object?.brandBookmarks?.splice(index);
        }
      }
    },

    async refreshBrandExclusions( { commit, state, getters }): Promise<void> {
      if(getters.isUserAuthenticated()) {
        await state.brandExclusions.load(async () => {
          return (await BrandExclusionsService.getBrandExclusionsCurrentUserDefaultAccount()).map(i => new BusyObject(i));
        }); 
      }
      else {
        state.brandExclusions.list = [];
      }
    },
    async addBrandExclusion({ state, getters }, brandId): Promise<void> {
      if(getters.isUserAuthenticated()) {
        if(!state.brandExclusions.list.find(i => i.object?.id === brandId)) {
          const newBrandExclusion: BrandExclusion = { id: brandId };
          const newBusyBrandExclusion = new BusyObject<BrandExclusion>(newBrandExclusion);
          state.brandExclusions.list.push(newBusyBrandExclusion);
          await newBusyBrandExclusion.create(async () => {
            return await BrandExclusionsService.createBrandExclusionCurrentUserDefaultAccount(newBrandExclusion);
          });
        }
      }
    },
    async removeBrandExclusion({ state, getters }, brandId): Promise<void> {
      if(getters.isUserAuthenticated()) {
        const brandExclusion = state.brandExclusions.list.find(i => i.object?.id === brandId);
        if(brandExclusion) {
          const index = state.brandExclusions.list.indexOf(brandExclusion);
          state.brandExclusions.list.splice(index);
          await brandExclusion.delete(async () => {
            await BrandExclusionsService.deleteBrandExclusionCurrentUserDefaultAccount(brandId);
            return null;
          });
        }
      }
    },
  },
  modules: {
  }
})
