/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Domain } from '../models/Domain';
import type { DomainRequest } from '../models/DomainRequest';
import type { TopLevelDomain } from '../models/TopLevelDomain';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DomainsService {

    /**
     * Gets a list of supported top level domains.
     * @param type Filter by type
     * @param tags Filter by tags
     * @returns TopLevelDomain Success
     * @throws ApiError
     */
    public static getTopLevelDomains(
type?: string,
tags?: Array<string>,
): CancelablePromise<Array<TopLevelDomain>> {
        return __request({
            method: 'GET',
            path: `/api/v1/tlds`,
            query: {
                'type': type,
                'tags': tags,
            },
        });
    }

    /**
     * Gets the domain information for the specified domain name.
     * @param name The domain name
     * @returns Domain Success
     * @throws ApiError
     */
    public static getDomain(
name: string,
): CancelablePromise<Domain> {
        return __request({
            method: 'GET',
            path: `/api/v1/Domains/${name}`,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Gets the domain information for multiple top level domains.
     * @param requestBody The domain request
     * @returns Domain Success
     * @throws ApiError
     */
    public static getDomains(
requestBody?: DomainRequest,
): CancelablePromise<Array<Domain>> {
        return __request({
            method: 'POST',
            path: `/api/v1/Domains`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Gets the domain information for multiple top level domains.
     * @param requestBody The domain request
     * @returns Domain Success
     * @throws ApiError
     */
    public static searchDomains(
requestBody?: DomainRequest,
): CancelablePromise<Array<Domain>> {
        return __request({
            method: 'POST',
            path: `/api/v1/Domains/search`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}