
















import { BusyObject } from "@/models/Busy";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

interface Statistic {
    measures: {
        [category: string]: {
            [key: string]: number;
        };
    };
}

@Component({
    components: {
    }
})
export default class TotalBrandsBox extends Vue {
    // Member:
    public statistic = new BusyObject<Statistic>();

    // Properties:
    @Prop({required: false})
    public url?: string;

    @Prop({required: false})
    public data?: any;

    @Prop({required: true})
    public icon!: string;

    @Prop({required: true})
    public label!: string;

    @Prop({required: false})
    public counterColor?: string;

    @Prop({required: false})
    public descriptionColor?: string;

    @Prop({required: false})
    public iconColor?: string;

    // Watcher
    @Watch('url')
    public onUrlChanged() {
        this.loadStatistic();
    }

    @Watch('data')
    public onDataChanged() {
        this.loadStatistic();
    }

    // Getter:
    public get count(): number | null {
        try {
            return this.statistic.object?.measures['brand']['total'] ?? null;
        }
        catch {
            return null;
        }
    }

    // Component Lifecycle Methods:
    public mounted() {
        this.loadStatistic();
    }

    // Methods:
    public async loadStatistic() {
        await this.statistic.load(async () => {
            if(this.data) 
                return this.data as Statistic;
            else if(this.url) 
                return await this.$util.getData(this.url);
            else 
                return null;
        });
    }
}
