















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class TruncateTooltip extends Vue {
    public disabled = true;

    // Properties
    @Prop({required: true})
    public label!: string;

    @Prop({ required: false, default: true, type: Boolean })
    public top!: boolean;
    
    @Prop({ required: false, default: false, type: Boolean })
    public bottom!: boolean;
    
    @Prop({ required: false, default: false, type: Boolean })
    public left!: boolean;
    
    @Prop({ required: false, default: false, type: Boolean })
    public right!: boolean;

    // Event Handler:
    public onHover(event: any) {
        const { target } = event;
        this.disabled = !(target.offsetWidth < target.scrollWidth);
    }
}
