export const brandStateCategoryIcons = [
    { key: 'New', icon: 'fa-light fa-shield-exclamation' },  
    { key: 'Alive', icon: 'fa-light fa-shield-check' },  
    { key: 'Dead', icon: 'fa-light fa-shield-xmark' },  
];

export const brandStateCategoryColors = [
    { key: 'New', color: 'orange lighten-1' },  
    { key: 'Alive', color: 'green darken-3' },  
    { key: 'Dead', color: 'grey darken-3' },  
];