







import { BusyObject } from "@/models/Busy";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Chart, { KeyValueData, Series } from '@/components/Statistics/Chart.vue';

interface Statistic {
    measures: {
        [category: string]: {
            [key: string]: number;
        };
    };
}

@Component({
    components: {
        Chart
    }
})
export default class BrandsByStateCategoryDonutChart extends Vue {
    // Member:
    public statistic = new BusyObject<Statistic>();

    // Properties:
    @Prop({required: false})
    public url?: string;

    @Prop({required: false})
    public data?: any;

    @Prop({required: false})
    public height?: number | string;

    @Prop({required: false})
    public width?: number | string;

    @Prop({required: false})
    public size?: string;

    @Prop({required: false})
    public responsiveBreakpoint?: number;

    @Prop({required: false})
    public legendPosition?: 'top' | 'right' | 'bottom' | 'left';

    @Prop({required: false, default: true, type: Boolean})
    public totalEnabled?: boolean;

    // Watcher
    @Watch('url')
    public onUrlChanged() {
        this.loadStatistic();
    }

    @Watch('data')
    public onDataChanged() {
        this.loadStatistic();
    }

    // Getter:
    public get series(): Series[] {
        const result: Series[] = [];

        try {
            const type = this.statistic.object?.measures['stateCategory'];

            if(!type) {
                return result;
            }

            const series = new Series('Trademarks by State');

            if(type['new']) {
                series.add({key: this.$store.getters.getBrandStateCategory('New')?.description ?? 'New', value: type['new'], color: '#FEB019'} as KeyValueData)
            }
            if(type['alive']) {
                series.add({key: this.$store.getters.getBrandStateCategory('Alive')?.description ?? 'Alive', value: type['alive'], color: '#00E396'} as KeyValueData)
            }
            if(type['dead']) {
                series.add({key: this.$store.getters.getBrandStateCategory('Dead')?.description ?? 'Dead', value: type['dead'], color: '#546E7A'} as KeyValueData)
            }

            result.push(series);
        }
        catch(error) {
            console.log(error);
        }

        return result;
    }

    // Component Lifecycle Methods:
    public mounted() {
        this.loadStatistic();
    }

    // Methods:
    public async loadStatistic() {
        await this.statistic.load(async () => {
            if(this.data) 
                return this.data as Statistic;
            else if(this.url) 
                return await this.$util.getData(this.url);
            else 
                return null;
        });
    }
}
