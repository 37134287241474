/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountAddress } from '../models/AccountAddress';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AddressesService {

    /**
     * Gets the billing address of the default account of the currently logged in user.
     * @returns AccountAddress Success
     * @throws ApiError
     */
    public static getBillingAddressCurrentUserDefaultAccount(): CancelablePromise<AccountAddress> {
        return __request({
            method: 'GET',
            path: `/api/v1/user/account/Addresses/billing`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Creates or updates the billing address of the default account for the currently logged in user.
     * @param requestBody The new or updated billing address.
     * @returns AccountAddress Success
     * @throws ApiError
     */
    public static upsertBillingAddressCurrentUserDefaultAccount(
requestBody?: AccountAddress,
): CancelablePromise<AccountAddress> {
        return __request({
            method: 'PUT',
            path: `/api/v1/user/account/Addresses/billing`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Gets the billing address for the currently logged in user for the specified account id.
     * @param id 
     * @returns AccountAddress Success
     * @throws ApiError
     */
    public static getBillingAddressCurrentUser(
id: number,
): CancelablePromise<AccountAddress> {
        return __request({
            method: 'GET',
            path: `/api/v1/accounts/${id}/Addresses/billing`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}