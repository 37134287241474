/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandQueryHistoryItem } from '../models/BrandQueryHistoryItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BrandQueryHistoryService {

    /**
     * Gets a brand query history item.
     * @param id The brand query history id.
     * @returns BrandQueryHistoryItem Success
     * @throws ApiError
     */
    public static getBrandQueryHistoryItem(
id: string,
): CancelablePromise<BrandQueryHistoryItem> {
        return __request({
            method: 'GET',
            path: `/api/v1/BrandQueryHistory/${id}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Gets the brand query history for a brand query.
     * @param id The brand query id.
     * @returns BrandQueryHistoryItem Success
     * @throws ApiError
     */
    public static getBrandQueryHistory(
id: number,
): CancelablePromise<Array<BrandQueryHistoryItem>> {
        return __request({
            method: 'GET',
            path: `/api/v1/BrandQueries/${id}/history`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}