import { UrlString } from "@azure/msal-browser";
import _Vue from "vue";

export class Util {
    public copyToClipboard(text: string): void {
        // Try to copy in recommended way:
        navigator.clipboard.writeText(text).then(null, function (err) {
            // It failed. Try to copy it the obsolete way:
            const listener = (e: ClipboardEvent) => {
                if (e.clipboardData) {
                    e.clipboardData.setData('text/plain', text);
                    e.preventDefault();
                }
                document.removeEventListener('copy', listener);
            }
            document.addEventListener('copy', listener);
            document.execCommand('copy');
        });
    }

    public absoluteUrl(path: string): string {
        return new URL(path, window.location.origin).href;
    }

    public concat(separator: string, ...args: (string | undefined | null)[]): string {
        return args.join(separator);
    }

    public setQueryParam(key: string, value: string): void {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
      
        // Setze den Wert des Query-Parameters
        searchParams.set(key, value);
      
        // Aktualisiere die URL mit den geänderten Query-Parametern
        url.search = searchParams.toString();
      
        // Navigiere zur aktualisierten URL
        window.location.href = url.toString();
    }

    public async getData<T>(url: string): Promise<T> {
        const response = await fetch(url);
        const json = await response.json();
        return json as T;
    }
}

export const util = new Util();

export function UtilPlugin(Vue: typeof _Vue): void {
    Vue.prototype.$util = util;
}

declare module 'vue/types/vue' {
    interface Vue {
        $util: Util;
    }
}