import _Vue from "vue";
import store from '@/store';
import { PublicClientApplication, Configuration, AccountInfo, AuthenticationResult, RedirectRequest, EndSessionRequest, ServerError, ProtocolMode, AuthenticationScheme } from '@azure/msal-browser';
import { OpenAPI } from '@/api/braendz'
import { emitter } from "@/plugins/emitter";
import { GlobalEventType } from "@/models/GlobalEventType";

const b2cPolicies = {
    names: {
        signUpSignIn: new String(process.env.VUE_APP_AUTH_POLICY_SIGNUPSIGNIN ?? "").toString(),
        forgotPassword: new String(process.env.VUE_APP_AUTH_POLICY_RESETPWD ?? "").toString(),
        editProfile: new String(process.env.VUE_APP_AUTH_POLICY_EDITPROFILE ?? "").toString()
    },
    authorities: {
        signUpSignIn: {
            authority: new String(process.env.VUE_APP_AUTH_AUTHORITY_SIGNUPSIGNIN ?? "").toString(),
        },
        forgotPassword: {
            authority: new String(process.env.VUE_APP_AUTH_AUTHORITY_RESETPWD ?? "").toString(),
        },
        editProfile: {
            authority: new String(process.env.VUE_APP_AUTH_AUTHORITY_EDITPROFILE ?? "").toString(),
        }
    },
    authorityDomain: "braendz.b2clogin.com"
}

const configuration: Configuration = {
    auth: {
        clientId: process.env.VUE_APP_AUTH_CLIENT_ID ?? "",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: window.location.origin,
        protocolMode: ProtocolMode.AAD,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    }
};

const scopes: (string)[] = [process.env.VUE_APP_AUTH_SCOPE_BRAENDZ_API ?? ""];

export class Auth {
    // Private fields:
    private _msalInstance: PublicClientApplication;
    private _scopes: string[];

    // Public Properties:
    public get account(): AccountInfo | null {
        let activeAccount = this._msalInstance.getActiveAccount();
        if(!activeAccount) {
            const accounts = this._msalInstance.getAllAccounts();
            activeAccount = accounts.length === 0 ? null : accounts[0];
            this._msalInstance.setActiveAccount(activeAccount);
        }
        return activeAccount;
    }

    public get isLoggedIn(): boolean {
        return this.account ? true : false;
    }
    
    constructor(configuration: Configuration, scopes: string[]) {
        this._scopes = scopes;
        this._msalInstance = new PublicClientApplication(configuration);
        this.handleRedirectPromise();
    }

    // This must be called once on startup of this class
    // See: https://learn.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2
    private async handleRedirectPromise(): Promise<void> {
        await this._msalInstance.handleRedirectPromise();
        store.dispatch("updateUserContext", this.account);
    }

    public async getToken(): Promise<string> {
        const account = this.account;

        if (account) {
            try {
                const authenticationResult = await this._msalInstance.acquireTokenSilent({ scopes: this._scopes, account: account });
                return authenticationResult ? authenticationResult.accessToken : "";
            }
            catch (error) {
                console.error(`Failed to get token silently for user ${account.username}: ${error}`);

                if(error instanceof Error && error.message.indexOf("interaction_required") !== -1) {
                    await this._msalInstance.acquireTokenRedirect({ scopes: this._scopes, account: account });
                }
            }
        }

        return "";
    }

    public async signIn(redirectUrl?: string) {
        try {
            const scopes = this._scopes;

            await this._msalInstance.loginRedirect({
                scopes: scopes,
                authority: b2cPolicies.authorities.signUpSignIn.authority,
                redirectUri: redirectUrl
            });
        }
        catch (error) {
            emitter.emit(GlobalEventType.ErrorOccurred, error);
            console.error(`Sign in failed: ${error}`);
            throw error;
        }
    }
    public async signOut(redirectUrl?: string) {
        try {
            const endSessionRequest = (redirectUrl ? { postLogoutRedirectUri: redirectUrl, account: this.account } : { account: this.account }) as EndSessionRequest;
            await this._msalInstance.logoutRedirect(endSessionRequest);
        }
        catch (error) {
            emitter.emit(GlobalEventType.ErrorOccurred, error);
            console.error(`Sign out failed: ${error}`);
            throw error;
        }
    }
    public async editProfile(redirectUrl?: string) {
        try {
            const scopes = this._scopes;

            await this._msalInstance.loginRedirect({
                loginHint: this.account?.username,
                account: this.account ?? undefined,
                authority: b2cPolicies.authorities.editProfile.authority,
                scopes: scopes,
                redirectUri: redirectUrl
            });
        }
        catch (error) {
            emitter.emit(GlobalEventType.ErrorOccurred, error);
            console.error(`Edit profile failed: ${error}`);
            throw error;
        }
    }
    public async resetPassword(redirectUrl?: string) {
        try {
            const scopes = this._scopes;
    
            await this._msalInstance.loginRedirect({
                loginHint: this.account?.username,
                account: this.account ?? undefined,
                authority: b2cPolicies.authorities.forgotPassword.authority,
                scopes: scopes,
                redirectUri: redirectUrl
            });
        }
        catch (error) {
            emitter.emit(GlobalEventType.ErrorOccurred, error);
            console.error(`Reset password failed: ${error}`);
            throw error;
        }
    }
}

export function AuthPlugin(Vue: typeof _Vue): void {
    const auth = new Auth(configuration, scopes);
    OpenAPI.TOKEN = auth.getToken.bind(auth);
    Vue.prototype.$auth = auth;
}

declare module 'vue/types/vue' {
    interface Vue {
        $auth: Auth;
    }
}