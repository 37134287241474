import _Vue from "vue";
import { TinyEmitter } from 'tiny-emitter';

export const emitter = new TinyEmitter();

export function EmitterPlugin(Vue: typeof _Vue): void {
    Vue.prototype.$emitter = emitter;
}

declare module 'vue/types/vue' {
    interface Vue {
        $emitter: TinyEmitter;
    }
}