





























import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator';
import { Locale, locales, findLocale } from '@/models/Locale';

@Component({})
export default class LocaleToolbarItem extends Vue {

    public get configuredLocaleOrDefault(): Locale {
      return findLocale(this.$i18n.locale) ?? findLocale('en-GB') ?? locales[0];
    }

    public locale: Locale | null = null;

    // Getter
    public get locales(): Locale[] {
      return locales;
    }

    // Watcher:
    @Watch('$i18n.locale')
    private oni18nLocaleChanged() {
      this.locale = this.configuredLocaleOrDefault;
    }

    // Component Lifecycle Methods:
    public mounted() {
      this.locale = this.configuredLocaleOrDefault;
    }

    // Methods:
    public changeLocale(locale: Locale): void {
      if (this.$i18n.locale !== locale.locale) {
        this.$i18n.locale = locale.locale;
        this.$vuetify.lang.current = locale.locale;
        this.$router.replace({ query: { lang: locale.locale } });
      }
    }
}
