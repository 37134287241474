var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"rounded":"","offset-y":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[(_vm.userAccount)?_c('span',[_vm._v(_vm._s(_vm.getInitials(_vm.userAccount)))]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-light fa-user")])],1)]}}])},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[(_vm.$env.featureSubscriptionActive() && _vm.userAccount)?_c('SubscriptionPopup',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attr,false),on),[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-light fa-money-check-dollar-pen")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("toolbar.account.subscription"))+" ")])],1)],1)]}}],null,false,639149404),model:{value:(_vm.subscriptionPopupOpened),callback:function ($$v) {_vm.subscriptionPopupOpened=$$v},expression:"subscriptionPopupOpened"}}):_vm._e(),(!_vm.userAccount)?_c('v-list-item',{on:{"click":function($event){return _vm.$auth.signIn()}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-light fa-right-to-bracket")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("toolbar.account.login"))+" ")])],1)],1):_vm._e(),(_vm.userAccount)?_c('v-list-item',{on:{"click":function($event){return _vm.$auth.editProfile()}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-light fa-user-gear")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("toolbar.account.editProfile"))+" ")])],1)],1):_vm._e(),(_vm.userAccount)?_c('v-list-item',{on:{"click":function($event){return _vm.$auth.resetPassword()}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-light fa-right-from-bracket")])],1),_c('v-list-item-content',[(_vm.userAccount)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("toolbar.account.resetPassword"))+" ")]):_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("toolbar.account.forgotPassword"))+" ")])],1)],1):_vm._e(),_c('CookieDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attr,false),on),[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-light fa-cookie-bite")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("toolbar.account.manageCookies"))+" ")])],1)],1)]}}]),model:{value:(_vm.cookieSettingsOpened),callback:function ($$v) {_vm.cookieSettingsOpened=$$v},expression:"cookieSettingsOpened"}}),(_vm.userAccount)?_c('v-list-item',{on:{"click":function($event){return _vm.$auth.signOut()}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-light fa-right-from-bracket")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("toolbar.account.logout"))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }