
















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class ConfirmationPopup extends Vue {

    // Properties
    @Prop({ required: false, default: false, type: Boolean })
    public model!: boolean;

    @Prop({ required: false })
    public title?: string;

    @Prop({ required: false })
    public text?: string;

    @Prop({ required: false })
    public icon?: string;

    @Prop({ required: false, default: false, type: Boolean })
    public yes!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public no!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public cancel!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public ok!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public question!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public exclamation!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public error!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public warning!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public info!: boolean;


    // Getter & Setter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }

    // Lifecycle Methods:

    // Methods:
    public close(): void {
        this.visible = false;
    }

    public onYes(): void {
        this.$emit('yes');
        this.close();
    }

    public onNo(): void {
        this.$emit('no');
        this.close();
    }

    public onOk(): void {
        this.$emit('ok');
        this.close();
    }

    public onCancel(): void {
        this.$emit('cancel');
        this.close();
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }
}
