/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Brand } from '../models/Brand';
import type { TaggedBrand } from '../models/TaggedBrand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BrandsService {

    /**
     * Gets the brand data.
     * @param id The brand id.
     * @returns Brand Success
     * @throws ApiError
     */
    public static getBrand(
id: string,
): CancelablePromise<Brand> {
        return __request({
            method: 'GET',
            path: `/api/v1/Brands/${id}`,
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Deletes a brand from the data storage.
     * @param id The brand id.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteBrand(
id: string,
): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/v1/Brands/${id}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Adds or updates a brand in the data storage.
     * @param skipMasterDataUpserts True, ignores master data and skips master data insert or update.
     * @param requestBody The new or existing brand.
     * @returns Brand Success
     * @throws ApiError
     */
    public static upsertBrand(
skipMasterDataUpserts: boolean = false,
requestBody?: TaggedBrand,
): CancelablePromise<Brand> {
        return __request({
            method: 'PUT',
            path: `/api/v1/Brands`,
            query: {
                'skipMasterDataUpserts': skipMasterDataUpserts,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}