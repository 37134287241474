/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterDataItem } from '../models/MasterDataItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RegistrationOfficeCodesService {

    /**
     * Gets all known registration office codes and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getRegistrationOfficeCodes(): CancelablePromise<Array<MasterDataItem>> {
        return __request({
            method: 'GET',
            path: `/api/v1/RegistrationOfficeCodes`,
        });
    }

    /**
     * Adds or updates a registration office code in the data storage.
     * @param requestBody The new or existing registration office code.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static upsertRegistrationOfficeCode(
requestBody?: MasterDataItem,
): CancelablePromise<MasterDataItem> {
        return __request({
            method: 'PUT',
            path: `/api/v1/RegistrationOfficeCodes`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets the registration office code by its key and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The key.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getRegistrationOfficeCodeByKey(
key: string,
): CancelablePromise<MasterDataItem> {
        return __request({
            method: 'GET',
            path: `/api/v1/RegistrationOfficeCodes/${key}`,
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Deletes the registration office code by its key.
     * @param key The key.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRegistrationOfficeCode(
key: string,
): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/v1/RegistrationOfficeCodes/${key}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}