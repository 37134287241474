






























































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import initials from "initials";
import { AccountInfo } from "@azure/msal-browser";
import { IdTokenClaim } from "@/models/IdTokenClaim";

import CookieDialog from "@/components/Cookies/CookieDialog.vue";
import SubscriptionPopup from "@/components/Account/SubscriptionPopup.vue";

@Component({
  components: {
    CookieDialog,
    SubscriptionPopup
  }
})
export default class AccountToolbarItem extends Vue {
  public subscriptionPopupOpened = false;
  public cookieSettingsOpened = false;

  // Getter:
  public get userAccount(): AccountInfo | null {
    return this.$store.state.userAccount;
  }

  // Methods:
  public getInitials(userAccount: AccountInfo | null): string | null {
    if(userAccount) {
      if(userAccount.name) {
        return initials(userAccount.name);  
      }
      if(userAccount.idTokenClaims) {
        let firstName: string | undefined = undefined;
        let lastName: string | undefined = undefined;

        if((userAccount.idTokenClaims as IdTokenClaim).given_name) {
          firstName = (userAccount.idTokenClaims as IdTokenClaim).given_name;
        }

        if((userAccount.idTokenClaims as IdTokenClaim).family_name) {
          lastName = (userAccount.idTokenClaims as IdTokenClaim).family_name;
        }

        if(firstName && lastName) {
          return initials(`${firstName} ${lastName}`);
        } else if(lastName) {
          return initials(lastName);
        } else if(firstName) {
          return initials(firstName);
        }
      }
      return initials(userAccount.username);
    }

    return null;
  }

}
