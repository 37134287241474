


























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BrandAddress } from '@/api/braendz';
import { AccountInfo } from "@azure/msal-browser";

@Component({})
export default class BrandDetailsAddressField extends Vue {

    // Properties

    @Prop({ required: true })
    public title!: string;

    @Prop({ required: false })
    public value?: BrandAddress | null | undefined;

    @Prop({ required: false })
    public width?: number;

    @Prop({ required: false, type: Boolean, default: true })
    public loginRequired?: boolean;

    // Getter
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }
}
