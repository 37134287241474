/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterDataItem } from '../models/MasterDataItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BrandStateCategoryService {

    /**
     * Gets all brand state categories and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getBrandStateCategories(): CancelablePromise<Array<MasterDataItem>> {
        return __request({
            method: 'GET',
            path: `/api/v1/BrandStateCategory`,
        });
    }

    /**
     * Adds or updates a brand state category in the data storage.
     * @param requestBody The new or existing brand state category.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static upsertBrandStateCategory(
requestBody?: MasterDataItem,
): CancelablePromise<MasterDataItem> {
        return __request({
            method: 'PUT',
            path: `/api/v1/BrandStateCategory`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets the brand state category by its key and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The key.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getBrandStateCategoryByKey(
key: string,
): CancelablePromise<MasterDataItem> {
        return __request({
            method: 'GET',
            path: `/api/v1/BrandStateCategory/${key}`,
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Deletes the brand state category by its key.
     * @param key The key.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteBrandStateCategory(
key: string,
): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/v1/BrandStateCategory/${key}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}