




































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyObject } from '@/models/Busy';
import { Brand, BrandsService } from '@/api/braendz';

import BrandDetails from '@/components/Brands/BrandDetails.vue'
import SocialMediaButton from '@/components/Social/SocialMediaButton.vue'

@Component({
    components: {
        BrandDetails,
        SocialMediaButton
    }
})
export default class BrandDetailsPopup extends Vue {

    // Properties
    public visible = false;
    public brand = new BusyObject<Brand>();

    @Prop({ required: true })
    public id!: string;

    // Getter
    public get socialMediaTitle(): string {
        return this.brand.object?.name ?? "Braendz Brand";
    }

    // Watcher
    @Watch('visible')
    public onVisibleChanged(visible: boolean): void {
        if(visible && !this.brand.isBusy && !this.brand.object) {
            this.reloadBrand();
        }
    }

    // Lifecycle Methods:

    // Methods:
    public async reloadBrand() {
        await this.brand.load(async () => {
            return await BrandsService.getBrand(this.id);
        });
    }

}
