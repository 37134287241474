export enum RobotOption {
    All = "all",
    NoIndex = "noindex",
    NoFollow = "nofollow",
    none = "none",
    NoArchive = "noarchive",
    NoSiteLinksSearchBox = "nositelinkssearchbox",
    NoSnippet = "nosnippet",
    IndexIfEmbedded = "indexifembedded",
    MaxSnippet = "max-snippet",
    MaxImagePreview = "max-image-preview",
    MaxVideoPreview = "max-video-preview",
    NoTranslate = "notranslate",
    NoImageIndex = "noimageindex",
    UnavailableAfter = "unavailable_after"
}

export enum TwitterCardOption {
    Summary = "summary",
    SummaryLargeImage = "summary_large_image",
    App = "app",
    Player = "player",
}
