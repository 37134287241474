/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StoredBrandQuery } from '../models/StoredBrandQuery';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class StoredBrandQueriesService {

    /**
     * Gets the stored brand query by its id.
     * @param id The stored brand query id.
     * @returns StoredBrandQuery Success
     * @throws ApiError
     */
    public static getStoredBrandQuery(
id: number,
): CancelablePromise<StoredBrandQuery> {
        return __request({
            method: 'GET',
            path: `/api/v1/StoredBrandQueries/${id}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Deletes the stored brand query by its id.
     * @param id The stored brand query id.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteStoredBrandQuery(
id: number,
): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/v1/StoredBrandQueries/${id}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Gets the stored brand queries.
     * @param executionFrequency To filter specific agent execution settings.
     * @returns StoredBrandQuery Success
     * @throws ApiError
     */
    public static getStoredBrandQueries(
executionFrequency?: string,
): CancelablePromise<Array<StoredBrandQuery>> {
        return __request({
            method: 'GET',
            path: `/api/v1/StoredBrandQueries`,
            query: {
                'executionFrequency': executionFrequency,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                422: `Client Error`,
            },
        });
    }

    /**
     * Gets the stored brand queries of the default account of the currently logged in user.
     * @returns StoredBrandQuery Success
     * @throws ApiError
     */
    public static getStoredBrandQueriesCurrentUserDefaultAccount(): CancelablePromise<Array<StoredBrandQuery>> {
        return __request({
            method: 'GET',
            path: `/api/v1/user/account/StoredBrandQueries`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Creates or updates the provided stored brand query for the default account and the currently logged in user.
     * @param requestBody The new or updated stored brand query.
     * @returns StoredBrandQuery Success
     * @throws ApiError
     */
    public static upsertStoredBrandQueryCurrentUserDefaultAccount(
requestBody?: StoredBrandQuery,
): CancelablePromise<StoredBrandQuery> {
        return __request({
            method: 'PUT',
            path: `/api/v1/user/account/StoredBrandQueries`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Client Error`,
            },
        });
    }

}