/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoodsServicesQuery } from '../models/GoodsServicesQuery';
import type { GoodsServicesQueryResult } from '../models/GoodsServicesQueryResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class GoodsServicesService {

    /**
     * Queries all known goods and services by any semantic description and considers the specified language code in the request header or by the optional URL parameter 'culture'.
     * @param requestBody The query options and text.
     * @returns GoodsServicesQueryResult Success
     * @throws ApiError
     */
    public static executeGoodsServicesQuery(
requestBody?: GoodsServicesQuery,
): CancelablePromise<GoodsServicesQueryResult> {
        return __request({
            method: 'POST',
            path: `/api/v1/GoodsServices/query`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Client Error`,
            },
        });
    }

}