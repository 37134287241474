



























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Notification, NotificationSeverity } from '../models/Notification';
import { GlobalEventType } from '@/models/GlobalEventType';
import { DescribedError } from '@/models/Errors';
import { ApiError } from '@/api/braendz';

@Component({})
export default class NotificationBar extends Vue {
    public color = "info";
    public icon = "fa-light fa-circle-info";
    public show = false;
    public timeout: number = +(process.env.VUE_APP_NOTIFICATION_DISPLAY_TIME ?? 10000);
    public text: string | null = null;

    @Prop({ type: String, default: "info-light" })
    public infoColor!: string;

    @Prop({ type: String, default: "warning-light" })
    public warningColor!: string;

    @Prop({ type: String, default: "error-light" })
    public errorColor!: string;

    // Component Lifecycle Methods:
    public created(): void {
        // eslint-disable-next-line
        this.$emitter.on(GlobalEventType.ErrorOccurred, (error: any) => {
            
            if((error as DescribedError).friendlyMessage) {
                this.showError(error.friendlyMessage);
            }
            else if((error as ApiError).message) {
                this.showError(error.message);
            }
            else if((error as Error).message) {
                this.showError(error.message);
            }
            else {
                this.showError(error.toString());
            }

        });

        this.$emitter.on(GlobalEventType.Notified, (notification: Notification) => {
            if(notification.severity === NotificationSeverity.Info) {
                this.showInfo(notification.text);
            }
            else if(notification.severity === NotificationSeverity.Warning) {
                this.showWarning(notification.text);
            }
            else if(notification.severity === NotificationSeverity.Error) {
                this.showError(notification.text);
            }
        });
    }

    public showInfo(text: string): void {
        this.showNotification(text, this.infoColor, 'fa-light fa-circle-info');
    }

    public showWarning(text: string): void {
        this.showNotification(text, this.warningColor, 'fa-light fa-triangle-exclamation');
    }

    public showError(text: string): void {
        this.showNotification(text, this.errorColor, 'fa-light fa-circle-xmark');
    }

    public showNotification(text: string, color: string, icon: string): void {
        this.text = text;
        this.color = color;
        this.icon = icon;
        this.show = true;
    }
 }

