








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ApexOptions } from 'apexcharts';

export interface NumericData {
    x: number,
    y: number,
    color?: string,
}

export interface KeyValueData {
    key: string,
    value: number,
    color?: string,
}

export class Series {
    public dataset: (NumericData | KeyValueData)[] = [];
    public name: string;
    
    public constructor(name: string) {
        this.name = name;
    }

    public add(item: NumericData | KeyValueData): void {
        this.dataset.push(item);
    }
}


@Component({
    components: {}
})
export default class Chart extends Vue {
    // Member:

    // Properties:
    @Prop({required: true})
    public type!: 'line' | 'area' | 'bar' | 'pie' | 'donut' | 'radialBar' | 'scatter' | 'bubble' | 'heatmap' | 'candlestick' | 'boxPlot' | 'radar' | 'polarArea' | 'rangeBar' | 'rangeArea' | 'treemap';

    @Prop({required: true})
    public series!: Series[];

    @Prop({required: false})
    public formatter?: | 'million' | 'thousand';

    @Prop({required: false})
    public horizontal?: boolean;

    @Prop({required: false})
    public height?: number | string;

    @Prop({required: false})
    public width?: number | string;

    @Prop({required: false})
    public size?: string;

    @Prop({required: false})
    public legendPosition?: 'top' | 'right' | 'bottom' | 'left';

    @Prop({required: false})
    public responsiveBreakpoint?: number;

    @Prop({required: false, default: true, type: Boolean})
    public tooltipEnabled?: boolean;

    @Prop({required: false, default: true, type: Boolean})
    public lablesEnabled?: boolean;

    @Prop({required: false, default: true, type: Boolean})
    public totalEnabled?: boolean;

    @Prop({required: false})
    public xAxisTitle?: string;

    @Prop({required: false})
    public yAxisTitle?: string;

    // Getter:
    public get options(): ApexOptions {

        const labels: string[] = [];
        const categories: string[] = [];
        const colors: string[] = [];
        const series: any[] = [];
        let xasisType: 'category' | 'datetime' | 'numeric' = 'category';

        if(this.series && this.series.length > 0) {
            if(this.type === 'pie' || this.type === 'donut' || this.type === 'radialBar') {
                if(this.series[0]) {
                    for(const item of this.series[0].dataset) {
                        if(item as KeyValueData) {
                            const data = item as KeyValueData;
                            labels.push(data.key);
                            series.push(data.value);
                            if(data.color) {
                                colors.push(data.color);
                            }
                        }
                        else {
                            const data = item as NumericData;
                            labels.push(data.x.toString());
                            series.push(data.y);
                            if(data.color) {
                                colors.push(data.color);
                            }
                        }
                    }
                }
            }
            else {
                for(const seriesItem of this.series) {
                    const data: any[] = [];
                    for(const item of seriesItem.dataset) {
                        if(item as KeyValueData) {
                            const keyValueData = item as KeyValueData;
                            data.push({x: keyValueData.key, y: keyValueData.value});
                            xasisType = 'category';
                        }
                        else {
                            const numericData = item as NumericData;
                            data.push({x: numericData.x, y: numericData.y});
                            xasisType = 'numeric';
                        }
                    }

                    series.push({ name: seriesItem.name, data: data });
                }
            }
        }

        const result = {
            chart: {
                type: this.type,
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                type: xasisType,
                categories: categories,
                labels: {
                    hideOverlappingLabels: true,
                },
                title: {
                    text: this.xAxisTitle
                }
            },
            yaxis: {
                labels: {
                },
                title: {
                    text: this.yAxisTitle
                }
            },
            tooltip: {
                enabled: this.tooltipEnabled
            },
            colors: colors,
            labels: labels,
            series: series,
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: this.horizontal,
                    dataLabels: {
                      position: 'top'
                    }
                },
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                showAlways: this.totalEnabled,
                                show: this.totalEnabled
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: this.lablesEnabled,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                },
            },
            legend: {
            },
            responsive: [{
                options: {
                    legend: {
                    }
                }
            }]
        } as any;

        // Set width & hight:
        // if(this.width) {
        //     result.chart.width = this.width;
        // }
        // if(this.height) {
        //     result.chart.height = this.height;
        // }
        if(this.size) {
            result.plotOptions.pie.donut.size = this.size;
        }

        // Responsive:
        if(this.responsiveBreakpoint) {
            result.responsive = [
                {
                    breakpoint: this.responsiveBreakpoint,
                    options: {
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ]
        } else {
            result.responsive = [
                {
                    breakpoint: Number.MAX_VALUE,
                    options: {
                        legend: {
                            position: "right"
                        }
                    }
                }
            ]
        }

        // Legend:
        if(this.legendPosition) {
            result.legend.position = this.legendPosition;
        }

        // Set custom formatter:
        if(this.formatter) {
            const labelFormatter = this.formatter === 'million' ? this.formatLabelMillion : this.formatAxisThousand;
            const axisFormatter = this.formatter === 'million' ? this.formatAxisMillion : this.formatAxisThousand;

            if(result.dataLabels) {
                result.dataLabels.formatter = labelFormatter;
            }

            const yaxis = result?.yaxis?.labels;
            if(yaxis) {
                yaxis.formatter = axisFormatter
            }
        }
        
        return result;
    }

    // Component Lifecycle Methods:

    // Methods:

    public formatLabelMillion(val: string | number | number[]): string | number {
        if(typeof(val) === 'number') {
            return `${this.$n(+(val / 1000000).toFixed(1))} M`;
        }
        else if(typeof(val) === 'string') {
            return val;
        }
        else {
            return 'Not Supported'
        }
    }

    public formatLabelThousand(val: string | number | number[]): string | number {
        if(typeof(val) === 'number') {
            return `${this.$n(Math.round(val / 1000))} K`;
        }
        else if(typeof(val) === 'string') {
            return val;
        }
        else {
            return 'Not Supported'
        }
    }

    public formatAxisMillion(val: number): string {
        return `${this.$n(+(val / 1000000).toFixed(1))} M`;
    }

    public formatAxisThousand(val: number): string {
        return `${this.$n(Math.round(val / 1000))} K`;
    }

}
