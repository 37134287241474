import { Guid } from "guid-typescript";

export class Notification {
    public key: string;
    public severity: NotificationSeverity;
    public text: string;
    public timestamp: Date;

    constructor(severity: NotificationSeverity, text: string, timestamp: Date = new Date()) {
        this.key = Guid.create().toString();
        this.severity = severity;
        this.text = text;
        this.timestamp = timestamp;
    }
}

export enum NotificationSeverity {
    Info = "INFO",
    Warning = "WARNING",
    Error = "ERROR"
}
