









































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import LocaleToolbarItem from './LocaleToolbarItem.vue';
import NotificationsToolbarItem from './NotificationsToolbarItem.vue';
import AccountToolbarItem from './AccountToolbarItem.vue';
import { PermissionResourceType } from '@/models/PermissionResourceType';
import { PermissionLevel } from '@/models/PermissionLevel';
import { AccountInfo } from "@azure/msal-browser";

@Component({ 
  components: { 
    LocaleToolbarItem,
    NotificationsToolbarItem,
    AccountToolbarItem
  }
})
export default class AppBar extends Vue {
  // Member:
  public drawer = false;

  // Getter / Setter:
  public get userAccount(): AccountInfo | null {
    return this.$store.state.userAccount;
  }
}
