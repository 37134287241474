/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssistantThread } from '../models/AssistantThread';
import type { AssistantThreadMessage } from '../models/AssistantThreadMessage';
import type { AssistantThreadStreamingUpdate } from '../models/AssistantThreadStreamingUpdate';
import type { RunCreationOptions } from '../models/RunCreationOptions';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AssistantThreadsService {

    /**
     * Gets an assistant thread by its id.
     * @param id The id of the thread
     * @returns AssistantThread Success
     * @throws ApiError
     */
    public static getAssistantThread(
id: string,
): CancelablePromise<AssistantThread> {
        return __request({
            method: 'GET',
            path: `/api/v1/AssistantThreads/${id}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Adds a message to the thread.
     * @param id The id of the thread
     * @param requestBody User's text message
     * @returns AssistantThreadMessage Success
     * @throws ApiError
     */
    public static addAssistantThreadMessage(
id: string,
requestBody?: AssistantThreadMessage,
): CancelablePromise<AssistantThreadMessage> {
        return __request({
            method: 'POST',
            path: `/api/v1/AssistantThreads/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Runs / answers all pending messages from the user and returns a stream of updates.
     * @param id The id of the thread
     * @param requestBody 
     * @returns AssistantThreadStreamingUpdate Success
     * @throws ApiError
     */
    public static runAssistantThreadMessages(
id: string,
requestBody?: RunCreationOptions,
): CancelablePromise<Array<AssistantThreadStreamingUpdate>> {
        return __request({
            method: 'PATCH',
            path: `/api/v1/AssistantThreads/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes a thread.
     * @param id The id of the thread
     * @returns any Success
     * @throws ApiError
     */
    public static deleteAssistantThread(
id: string,
): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/v1/AssistantThreads/${id}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Creates a new assistant thread.
     * @returns AssistantThread Success
     * @throws ApiError
     */
    public static createAssistantThread(): CancelablePromise<AssistantThread> {
        return __request({
            method: 'POST',
            path: `/api/v1/AssistantThreads`,
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets a message from a thread.
     * @param threadId The id of the thread
     * @param id The id of the message
     * @returns AssistantThreadMessage Success
     * @throws ApiError
     */
    public static getAssistantThreadMessage(
threadId: string,
id: string,
): CancelablePromise<AssistantThreadMessage> {
        return __request({
            method: 'POST',
            path: `/api/v1/AssistantThreads/${threadId}/messages/${id}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}