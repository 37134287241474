































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BrandDetailsTextField extends Vue {

    // Properties

    @Prop({ required: true })
    public title!: string;

    @Prop({ required: false })
    public value?: string | number | Date | null | undefined;

    @Prop({ required: false })
    public value2?: string | number | Date | null | undefined;

    @Prop({ required: false })
    public alternative1?: string | number | Date | null | undefined;

    @Prop({ required: false })
    public alternative2?: string | number | Date | null | undefined;

    @Prop({ required: false })
    public width?: number;

    @Prop({ required: false, default: false, type: Boolean })
    public titleTop?: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public titleBottom?: boolean;

    @Prop({required: false })
    public wrap?: boolean;

}
