/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Package } from '../models/Package';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PackagesService {

    /**
     * Gets the package catalog and provides the localized name and description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param reload To force cache reloading.
     * @returns Package Success
     * @throws ApiError
     */
    public static getPackages(
reload?: boolean,
): CancelablePromise<Array<Package>> {
        return __request({
            method: 'GET',
            path: `/api/v1/Packages`,
            query: {
                'reload': reload,
            },
        });
    }

    /**
     * Gets the package by its id and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The package's key.
     * @param reload To force cache reloading.
     * @returns Package Success
     * @throws ApiError
     */
    public static getPackage(
key: string,
reload?: boolean,
): CancelablePromise<Package> {
        return __request({
            method: 'GET',
            path: `/api/v1/Packages/${key}`,
            query: {
                'reload': reload,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

}