
























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class TwoColumns extends Vue {
    // Member:

    // Properties:
    @Prop({required: false})
    public midPadding?: string;

    @Prop({required: false})
    public sidePadding?: string;

    @Prop({required: false})
    public yPadding?: string;

    @Prop({required: false, default: 'left'})
    public horizontalOrientationFirst?: 'left' | 'center' | 'right' | 'stretch';

    @Prop({required: false, default: 'left'})
    public horizontalOrientationSecond?: 'left' | 'center' | 'right' | 'stretch';

    @Prop({required: false, default: 'center'})
    public verticalOrientationFirst?: 'top' | 'center' | 'bottom' | 'stretch';

    @Prop({required: false, default: 'center'})
    public verticalOrientationSecond?: 'top' | 'center' | 'bottom' | 'stretch';

    @Prop({required: false, default: 'center'})
    public breakOn?: 'xs' | 'sm' | 'md' | 'lg';

    @Prop({required: false, default: 'left-first'})
    public orderOnBreak?: 'left-first' | 'right-first';

    // Getter:
    public get displayColumn() {
        switch(this.breakOn) {
            case 'xs': return this.$vuetify.breakpoint.xsOnly;
            case 'sm': return this.$vuetify.breakpoint.smAndDown;
            case 'md': return this.$vuetify.breakpoint.mdAndDown;
            case 'lg': return this.$vuetify.breakpoint.lgAndDown;
            default: return false;
        }
    }
    
    // Component Lifecycle Methods:
    
    // Methods:

}
