




























































import { Subscription } from '@/api/braendz';
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import SubscriptionState from '@/components/Account/SubscriptionState.vue'

@Component({
    components: {
        SubscriptionState
    }
})
export default class SubscriptionDetailsTable extends Vue {

    // Properties
    @Prop({ required: true })
    public subscription!: Subscription;

    // Getter & Setter

    // Watcher

    // Lifecycle Methods:

    // Methods:
}
