import colors from 'vuetify/lib/util/colors';

export default {
    primary: '#1996FF',
    secondary: '#061777',
    accent: '#0725DD',
    tertiary: '#2D7BFF',
    quaternary: '#1996FF',

    'background-light': '#EFF0FD',
    'background-dark': '#030F22',
    'text-light': '#041B2E',
    //'text-dark': colors.shades.white,
    'text-dark': '#FCFFFF',

    error: colors.deepOrange.darken4,
    info: colors.lightBlue.darken4,
    success: colors.green.darken4,
    warning: colors.yellow.darken4,
}