




































































import { MasterDataItem } from "@/api/braendz";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import BrandDetailsPopup from '@/components/Brands/BrandDetailsPopup.vue';
import TruncateTooltip from '@/components/TruncateTooltip.vue';

export interface BrandThumbnail {
    brandId: string;
    url?: string;
    name?: string;
    brandLogoUrl?: string;
    registrationOfficeCode: string;
    brandType?: string;
    brandState?: string;
    brandStateCategory?: string;
}

@Component({
    components: {
        TruncateTooltip,
        BrandDetailsPopup
    }
})
export default class BrandThumbnailCard extends Vue {
    // Member:
    @Prop({required: true})
    public thumbnail!: BrandThumbnail

    // Getter:
    public get registrationOfficeCode(): MasterDataItem | null {
        if(this.thumbnail.registrationOfficeCode) {
            return this.$store.getters.getRegistrationOfficeCode(this.thumbnail.registrationOfficeCode);
        }
        return null;
    }

    public get brandType(): MasterDataItem | null {
        if(this.thumbnail.brandType) {
            return this.$store.getters.getBrandType(this.thumbnail.brandType);
        }
        return null;
    }

    public get fullBrandLogoUrl(): string | null {
        if(this.thumbnail.brandLogoUrl) {
            return this.$braendz.getBrandLogoUrlByRelativeUrl(this.thumbnail.brandLogoUrl);
        }
        return null;
    }

    // Component Lifecycle Methods:

    // Methods:
}
