

























































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component({})
export default class ScrollDownButton extends Vue {
    // Members:

    // Properties:
    @Prop({required: false, default: 'relative'})
    public position?: 'relative' | 'absolute' | 'fixed';

    @Prop({required: false, default: false})
    public dark?: boolean;

    @Prop({required: false})
    public to?: string | Location;

    @Prop({required: false})
    public scroll?: boolean;

    @Prop({required: false})
    public scrollDistance?: number;

    @Prop({required: false})
    public scrollMaxY?: number;

    @Prop({required: false})
    public scrollElement?: Element;

    // Getters:

    // Component Lifecycle Methods:

    // Methods:

    // Events:
    public onClicked() {
      this.$emit("click");

      if(this.scroll) {
        const distance = this.scrollDistance ?? window.innerHeight / 4 * 3;
        const scrollTo = window.scrollY + distance;
        const scrollMaxY = this.scrollMaxY ?? this.scrollElement?.scrollHeight ?? window.innerHeight;

        (this.scrollElement ?? window).scrollTo({ 
          top: scrollTo > scrollMaxY ? this.scrollMaxY : scrollTo, 
          behavior: 'smooth'
        });
      }
    }
}
