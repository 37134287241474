/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Permission } from '../models/Permission';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PermissionsService {

    /**
     * Gets the permissions of the signed in user.
     * @returns Permission Success
     * @throws ApiError
     */
    public static getCurrentUserPermissions(): CancelablePromise<Array<Permission>> {
        return __request({
            method: 'GET',
            path: `/api/v1/user/Permissions`,
        });
    }

}