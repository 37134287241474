




































































import CookieComponent from './CookieComponent'
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'opened',
    event: 'openedChanged'
  }
})
export default class CookieDialog extends CookieComponent {

  // Members
  @Prop({required: false, default: false})
  public readonly opened!: boolean;

  // Getter / Setter:
  public get dialogOpenend(): boolean {
    return this.opened;
  }
  public set dialogOpenend(value: boolean) {
    this.$emit("openedChanged", value);
  }

  // Component Lifecycle Methods:

  // Methods
  public accept(): void {
    this.saveSettings();
    this.executeSettings();
    this.consentRequired = false;
    this.close();
  }

  public close() {
    this.dialogOpenend = false;
  }
}
